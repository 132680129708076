// core
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfigProvider } from 'antd';
import { Helmet } from 'react-helmet-async';

// store
import { appThemes, useThemeStore, useAppStore, useAuthStore } from 'store';
import { darkTheme, lightTheme } from 'config/theme';

//types
import { ERoutePermissionsType } from './hoc/RoutePermissions/types';

// api
import { $api, $apiAuth } from 'utils/request';

// hooks
import { useScrollToTop } from './hooks/general';
import {
  useMutateLogin,
  useQueryFetchProfileInfo,
  useQueryFetchCurrencyList,
  useMutationAssignReferral,
  useQueryFetchVariables,
} from './hooks/api';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

// hoc
import withErrorHandler from 'hoc/WithErrorHandler';
import RoutePermissions from './hoc/RoutePermissions';

// layouts
import { StaticLayout, UserLayout, EmptyLayout } from 'components/layouts';

// auth layout

import {
  Home,
  Catalog,
  Product,
  Blog,
  Article,
  SupportFaq,
  SupportTickets,
  SupportTicketsCreate,
  SupportTicketsChat,
  TextPage,
} from 'containers/static';

// user layout
// dashboard containers
import {
  Dashboard,
  DashboardProfile,
  DashboardReferral,
  DashboardSteam,
  DashboardSecurity,
  DashboardSettings,
  DashboardLanguage,
  Inventory,
  InventoryPending,
  InventoryProducts,
  Deals,
  DealsTrade,
  DealsAutobuy,
  History,
  HistoryPurchase,
  HistorySale,
  HistoryReplenishment,
  HistoryWithdrawal,
  InventorySelling,
} from 'containers/user';

import { Page404, PageMaintenance } from 'containers/static';

// components
import { SuccessIcon, CloseIcon, KeyIcon } from 'components/icons';
import { getVariable } from './utils/commons';

function App() {
  const { appTheme } = useThemeStore();
  const { sSetCurrentCurrency, sSetCommission } = useAppStore();
  const { accessToken: isAuth } = useAuthStore();
  const [query, setQuery] = useQueryParams({
    token: withDefault(StringParam, undefined),
    referral: withDefault(StringParam, undefined),
  });
  const { token, referral } = query;
  const [enabledFetchProfile, setEnabledFetchProfile] = useState(false);
  const [enabledCurrency, setEnabledCurrency] = useState(true);
  const { data: variablesList, isLoading: isLoadingVariablesList } =
    useQueryFetchVariables(true);
  useQueryFetchProfileInfo(enabledFetchProfile);

  useEffect(() => {
    if (variablesList && !isLoadingVariablesList) {
      if (
        getVariable(variablesList.data, 'commission_percentage') &&
        Number(getVariable(variablesList.data, 'commission_percentage')) > 0
      ) {
        sSetCommission(
          Number(getVariable(variablesList.data, 'commission_percentage')) /
            100,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variablesList, isLoadingVariablesList]);

  const {
    data: assignReferralData,
    isLoading: isLoadingAssignReferral,
    mutate: mutateAssignReferral,
  } = useMutationAssignReferral();

  const {
    data: loginResponse,
    isLoading: isLoadingLogin,
    mutate: mutateLogin,
  } = useMutateLogin();

  const { data: currencyList, isLoading: isLoadingCurrencyList } =
    useQueryFetchCurrencyList(enabledCurrency);

  useEffect(() => {
    if (
      (assignReferralData || assignReferralData === false) &&
      !isLoadingAssignReferral
    ) {
      localStorage.removeItem('referral');
    }
  }, [assignReferralData, isLoadingAssignReferral]);

  useEffect(() => {
    if (!isLoadingCurrencyList && currencyList) {
      if (!localStorage.getItem('currency')) {
        sSetCurrentCurrency(currencyList[0]);
      } else if (
        currencyList.find(el => el.sku === localStorage.getItem('currency'))
      ) {
        sSetCurrentCurrency(
          currencyList.find(el => el.sku === localStorage.getItem('currency')),
        );
      } else {
        sSetCurrentCurrency(currencyList[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyList, isLoadingCurrencyList]);

  useEffect(() => {
    if (token && token.length) {
      mutateLogin(token);
    }
    if (referral && referral.length) {
      localStorage.setItem('referral', referral.toString());
      resetQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const resetQuery = () => {
    setTimeout(() => {
      setQuery({ referral: undefined });
    });
  };

  useEffect(() => {
    if (isAuth) {
      if (localStorage.getItem('referral')) {
        mutateAssignReferral(localStorage.getItem('referral'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    if (!isLoadingLogin && loginResponse) {
      setQuery({ token: undefined });
      setEnabledFetchProfile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingLogin, loginResponse]);

  useScrollToTop();

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: appTheme === appThemes.dark ? darkTheme : lightTheme,
      }}
    >
      <div className="app">
        <Routes>
          {/*static*/}
          <Route
            path="/"
            element={
              <StaticLayout>
                <Home />
              </StaticLayout>
            }
          />
          <Route
            path="/market"
            element={
              <StaticLayout>
                <Catalog />
              </StaticLayout>
            }
          />
          <Route
            path="/market/:slug"
            element={
              <StaticLayout>
                <Product />
              </StaticLayout>
            }
          />
          <Route
            path="/blog"
            element={
              <StaticLayout>
                <Blog />
              </StaticLayout>
            }
          />
          <Route
            path="/blog/:slug"
            element={
              <StaticLayout>
                <Article />
              </StaticLayout>
            }
          />
          <Route
            path="/support/faq"
            element={
              <StaticLayout>
                <SupportFaq />
              </StaticLayout>
            }
          />
          <Route
            path="/support/tickets"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <StaticLayout>
                  <SupportTickets />
                </StaticLayout>
              </RoutePermissions>
            }
          />
          <Route
            path="/support/tickets/create"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <StaticLayout>
                  <SupportTicketsCreate />
                </StaticLayout>
              </RoutePermissions>
            }
          />
          <Route
            path="/support/ticket/:id"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <StaticLayout>
                  <SupportTicketsChat />
                </StaticLayout>
              </RoutePermissions>
            }
          />
          {/*dashboard*/}
          <Route
            path="/dashboard"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <Dashboard />
                </UserLayout>
              </RoutePermissions>
            }
          >
            <Route path="referral" element={<DashboardReferral />} />
            <Route path="profile" element={<DashboardProfile />} />
            <Route path="steam-account" element={<DashboardSteam />} />
            <Route
              path="security-and-payments"
              element={<DashboardSecurity />}
            />
            <Route path="site-settings" element={<DashboardSettings />} />
            <Route
              path="language-and-currency"
              element={<DashboardLanguage />}
            />
          </Route>
          {/*inventory*/}
          <Route
            path="/inventory"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <Inventory />
                </UserLayout>
              </RoutePermissions>
            }
          >
            <Route path="stock" element={<InventoryProducts />} />
            <Route path="sales" element={<InventorySelling />} />
            <Route path="pending" element={<InventoryPending />} />
          </Route>
          {/*deals*/}
          <Route
            path="/deals"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <Deals />
                </UserLayout>
              </RoutePermissions>
            }
          >
            <Route path="trade" element={<DealsTrade />} />
            <Route path="autobuy" element={<DealsAutobuy />} />
          </Route>
          {/*history*/}
          <Route
            path="/history"
            element={
              <RoutePermissions permissions={ERoutePermissionsType.Auth}>
                <UserLayout>
                  <History />
                </UserLayout>
              </RoutePermissions>
            }
          >
            <Route path="purchase" element={<HistoryPurchase />} />
            <Route path="sale" element={<HistorySale />} />
            <Route path="replenishment" element={<HistoryReplenishment />} />
            <Route path="withdrawal" element={<HistoryWithdrawal />} />
          </Route>
          <Route
            path="/pages/:slug"
            element={
              <StaticLayout>
                <TextPage />
              </StaticLayout>
            }
          />
          <Route
            path="/maintenance"
            element={
              <EmptyLayout>
                <PageMaintenance />
              </EmptyLayout>
            }
          />
          {/*static*/}
          <Route
            path="*"
            element={
              <StaticLayout>
                <Page404 />
              </StaticLayout>
            }
          />
        </Routes>

        {/*use toast instead ant notifications*/}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          icon={({ theme, type }) => {
            if (type === 'success') return <SuccessIcon />;
            if (type === 'info') return <SuccessIcon />;
            if (type === 'error') return <CloseIcon />;
            if (type === 'warning') return <KeyIcon />;
          }}
        />
      </div>
    </ConfigProvider>
  );
}

export default withErrorHandler(App, $api, $apiAuth);
